//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { SellerPermissions } from '@/modules/seller/seller-permissions';

export default {
  name: 'app-application-list-toolbar',

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      hasRows: 'seller/application/hasRows',
      loading: 'seller/application/loading',
      // exportLoading: 'seller/application/exportLoading',
      selectedRows: 'seller/application/selectedRows',
      destroyLoading: 'seller/destroyApplication/loading',
      rejectLoading: 'seller/destroyApplication/rejectLoading',
      contactLoading: 'seller/destroyApplication/contactLoading',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new SellerPermissions(this.currentUser).create;
    },

    hasPermissionToEdit() {
      return new SellerPermissions(this.currentUser).edit;
    },

    hasPermissionToImport() {
      return new SellerPermissions(this.currentUser).import;
    },

    exportButtonDisabled() {
      return (
        !this.hasRows || this.loading || this.exportLoading
      );
    },

    exportButtonTooltip() {
      return !this.hasRows
        ? this.i18n('common.noDataToExport')
        : null;
    },

    removeButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    removeButtonTooltip() {
      return !this.selectedRows.length
        ? this.i18n('common.mustSelectARow')
        : null;
    },

    enableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    enableButtonTooltip() {
      return !this.selectedRows.length
        ? this.i18n('common.mustSelectARow')
        : null;
    },

    disableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    disableButtonTooltip() {
      return !this.selectedRows.length
        ? this.i18n('common.mustSelectARow')
        : null;
    },
  },

  methods: {
    ...mapActions({
      doExport: 'seller/application/doExport',
      // doRemoveAllSelected: 'seller/application/doRemoveAllSelected',
      // doDisableAllSelected: 'seller/application/doDisableAllSelected',
      // doEnableAllSelected: 'seller/application/doEnableAllSelected',
      doDestroy: 'seller/destroyApplication/doDestroy',
      doDestroyAll: 'seller/destroyApplication/doDestroyAll',
      doRejectAll: 'seller/destroyApplication/doRejectAll',
      doContactAll: 'seller/destroyApplication/doContactAll',
    }),
    async doRemoveAllSelected() {
      try {
        const ids = this.selectedRows.map(el => el.id)
        await this.doDestroyAll(ids);
      } catch (error) {
        // no
      }
    },
    async doRejectAllSelected() {
      try {
        const ids = this.selectedRows.map(el => el.id)
        await this.doRejectAll(ids);
      } catch (error) {
        // no
      }
    },
    async doContactAllSelected() {
      try {
        const ids = this.selectedRows.map(el => el.id)
        await this.doContactAll(ids);
      } catch (error) {
        // no
      }
    },
     i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
