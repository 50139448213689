//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SellerForm from '@/modules/seller/components/seller-form';
import SavePinCode from '@/modules/seller/components/save-pin-code';
import firebase from 'firebase/app'
import 'firebase/firestore'
import { mapGetters, mapActions } from 'vuex';
import Message from '@/shared/message/message';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import moment from 'moment';

export default {
  name: 'app-seller-form-modal',

  props: ['visible', 'sellerType', 'id'],

  components: {
    [SellerForm.name]: SellerForm,
    [SavePinCode.name]: SavePinCode,
  },

  data() {
    return {
      successDialog: false,
      operation: undefined,
      pinCode: undefined,
    };
  },

  computed: {
    ...mapGetters({
      record: 'seller/form/record',
      findLoading: 'seller/form/findLoading',
      saveLoading: 'seller/form/saveLoading',
      pinCodeLoading: 'seller/form/pinCodeLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isEditing() {
      return !!this.id;
    },
    isAddSellerManuel() {
      return this.operation == 'addSeller' || this.sellerType == 'branch'
    },
    isGeneratePin() {
      return this.operation == 'generatePin'
    },
  },
  
  // async created() {
  //   if (this.isEditing) {
  //     await this.doFind(this.id);
  //   } else {
  //     await this.doNew();
  //   }
  // },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'seller/form/doFind',
      doNew: 'seller/form/doNew',
      doUpdate: 'seller/form/doUpdate',
      doCreate: 'seller/form/doCreate',
      doAdd: 'iam/form/doAdd',
      doGeneratePinCode: 'seller/form/doGeneratePinCode',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },

    async doGenerate() {
      // this.pinCode = await this.doGeneratePinCode()
      this.operation = 'generatePin'
    },
    async doSubmit(payload) {
      if (this.isEditing) {
        await this.doUpdate(payload);
        this.$emit('close');
      } else {
        // const docs = (await firebase.firestore().collection('user').where('phoneNumber','==',payload.values.telephone).get()).docs
        const users = FirebaseRepository.mapCollection(await firebase.firestore().collection('user').where('phoneNumber', '==', payload.values.telephone).get())
        if(!users.length){
          const isCreated = await this.doCreate(payload.values);
          let user = {
            avatars: [],
            disabled: false,
            firstName: payload.values.name,
            lastName: '',
            fullName: payload.values.name,
            phoneNumber: payload.values.telephone,
            roles: ['seller'],
            userType: "seller",
          }
          firebase.firestore().collection('user').add(user)
          this.$emit('close');
          if (isCreated) {
            this.successDialog = true
          }
        }
        else {
          const user = users[0]
          const userType = user.userType
          const language = localStorage.getItem('language')
          const date = moment(user.createdAt).locale(language).format("D MMM, YYYY h:mm A")
          switch (userType) {
            case 'client':
              language == 'ar'
                ? Message.error(`رقم الهاتف هذا مستخدم بالفعل كعميل في تطبيق الجوال بتاريخ (${date})`)
                : Message.error(`This phone number is already used as a client in the mobile app with date (${date})`)
              break;
            case 'seller':
              language == 'ar'
                ? Message.error(`تم استخدام رقم الهاتف هذا بالفعل كبائع في لوحة معلومات البائع بتاريخ (${date})`)
                : Message.error(`This phone number is already used as a seller in the seller dashboard with date (${date})`)
              break;
            case 'admin':
              language == 'ar'
                ? Message.error(`تم استخدام رقم الهاتف هذا بالفعل كمسؤول في لوحة تحكم المسؤول بتاريخ (${date})`)
                : Message.error(`This phone number is already used as an admin in the admin dashboard with date (${date})`)
              break;
            default:
              language == 'ar'
                ? Message.error(`رقم الهاتف هذا مستخدم بالفعل بتاريخ (${date})`)
                : Message.error(`This phone number is already used with date (${date})`)
              break;
          }
        }
      }
    },
  },
};
