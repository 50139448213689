import { render, staticRenderFns } from "./application-list-table.vue?vue&type=template&id=1bbe1651&scoped=true&"
import script from "./application-list-table.vue?vue&type=script&lang=js&"
export * from "./application-list-table.vue?vue&type=script&lang=js&"
import style0 from "./application-list-table.vue?vue&type=style&index=0&id=1bbe1651&lang=scss&scoped=true&"
import style1 from "./application-list-table.vue?vue&type=style&index=1&id=1bbe1651&lang=scss&scoped=true&"
import style2 from "./application-list-table.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bbe1651",
  null
  
)

export default component.exports
import {QTable,QInput,QIcon,QTr,QTh,QTd,QBtn,QSpinnerIos,QTooltip,QDialog,QCard} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QInput,QIcon,QTr,QTh,QTd,QBtn,QSpinnerIos,QTooltip,QDialog,QCard})
