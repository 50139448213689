export default class Printer {
  static doPrint(id) {
    console.log('RunProf custom printer');
    let head = `<head>${document.head.innerHTML}</head>`;
    var l = [
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css',
      // 'source/frontend/public/styles/css/pdf.css',
      'https://puricom-beebe.web.app/styles/css/pdf.css',
      // 'http://localhost:8081/styles/css/pdf.css',
      'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900%7CMaterial+Icons',
      'https://cdn.jsdelivr.net/npm/quasar@1.14.5/dist/quasar.min.css',
      // 'https://cdnjs.cloudflare.com/ajax/libs/angular-material-icons/0.7.1/angular-material-icons.min.js',
      'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
      // 'https://cdn.jsdelivr.net/npm/vue@^2.0.0/dist/vue.min.js',
      'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900%7CMaterial+Icons%7CMaterial+Icons+Outlined%7CMaterial+Icons+Round%7CMaterial+Icons+Sharp',
      'https://cdn.jsdelivr.net/npm/@mdi/font@^5.0.0/css/materialdesignicons.min.css',
      'https://use.fontawesome.com/releases/v5.0.13/css/all.css',
      'https://cdn.jsdelivr.net/npm/ionicons@^4.0.0/dist/css/ionicons.min.css',
      'https://cdn.jsdelivr.net/npm/eva-icons@^1.0.0/style/eva-icons.css',
      'https://themify.me/wp-content/themes/themify-v32/themify-icons/themify-icons.css',
      'https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css',
      'https://cdn.jsdelivr.net/npm/quasar@1.14.5/dist/quasar.min.css',
      // 'https://cdn.jsdelivr.net/npm/vue@^2.0.0/dist/vue.js',
      // 'https://cdn.jsdelivr.net/npm/quasar@1.14.5/dist/quasar.umd.js',
      'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fprint-style.css?alt=media&token=0cdb873c-d0bc-4c0a-b64a-d142bbb2aeed',
    ];
    var d = document.createElement('div');
    var link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');

    var mywindow = window.open('');
    mywindow.document.write(head);
    l.forEach((e) => {
      link.setAttribute('href', e);
      d.append(link);
      mywindow.document.write(d.innerHTML);
    });
    mywindow.document.write(
      document.getElementById(id).innerHTML,
    );

    setTimeout(() => {
      mywindow.print();
    //   mywindow.close();
    }, 5000);
  }
}
