//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import Message from '@/shared/message/message';

export default {
  name: 'app-save-pin-code',

  // props: ['pinCode'],

  // async mounted() {
  //   const filter = { distributorType: 'wholesaler' }
  //   await this.doFetch({
  //     filter,
  //   });
  // },

  data() {
    return {
      step: 1,
      distributorType: 'wholesaler',
      wholesalerOptions: [],
      pinCodes: [],
      numberOfRetailers: 1,
      wholesalerId: null,
      isWholesaler: true,
      isRetailer: false,
      indexOfClick: -1,
    };
  },

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      rows: 'seller/list/rows',
      count: 'seller/list/count',
      loading: 'seller/list/loading',
      pinCodeLoading: 'seller/form/pinCodeLoading',
    }),
    wholesaler() {
      return this.rows.filter(el => el.distributorType == 'wholesaler').map(item => {
        return {
          label: item.name,
          value: item.id, 
        }
      })
    },
    numOfCodes() {
      const num = parseInt(this.numberOfRetailers)
      return num
    }
  },

  methods: {
    ...mapActions({
      doGeneratePinCodes: 'seller/form/doGeneratePinCodes',
      doCreatePinCodes: 'seller/form/doCreatePinCodes',
      // doFetch: 'seller/list/doFetch',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.$emit('cancel')
    },

    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.wholesalerOptions = this.wholesaler
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.wholesalerOptions = this.wholesaler.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },

    next() { this.step++ },
    
    doCopyPinCode(index) {
      // let testingCodeToCopy = document.querySelector('#pin-code')
      const id = `#pin-code-${index}`
      console.log('ID = ', id);
      let testingCodeToCopy = document.querySelector(id)
      testingCodeToCopy.setAttribute('type', 'text')  
      testingCodeToCopy.select()
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Pin Code was copied ', msg);
        // const message = `common.`
        Message.success(this.i18n(`common.Pin Code was copied ${msg}`));
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        Message.error(this.i18n(`common.Oops, unable to copy`));
        // alert('Oops, unable to copy');
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()

      this.indexOfClick = index
      setTimeout(() => {
        this.indexOfClick = -1
      }, 400);
    },

    async doGenerate() {
      const count = this.numOfCodes
      this.pinCodes = await this.doGeneratePinCodes(count)
      // this.operation = 'generatePin'
    },

    async doSubmit() {
      if (this.step == 1) {
        await this.doGenerate();
        return this.next()
      }


      const values = {
        distributorType: this.distributorType,
        wholesalerId: this.distributorType !== 'wholesaler'?this.wholesalerId:null,
        pinCodes: this.pinCodes,
      }
      await this.doCreatePinCodes(values)
      this.$emit('cancel')
    },
    
    // async doSubmit() {
    //   await this.doCreatePinCodes(this.pinCodes)
    //   this.$emit('cancel')
    // },
  },

  watch:{
    isRetailer(newVal) {
      if(newVal){
        this.distributorType = 'retailer'
        this.numberOfRetailers = undefined
        this.isWholesaler = false
      }
    },
    isWholesaler(newVal) {
      if(newVal){
        this.distributorType = 'wholesaler'
        this.numberOfRetailers = 1
        this.isRetailer = false
      }
    },
  },
};
