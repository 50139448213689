//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { SellerModel } from '@/modules/seller/seller-model';
import TableViewPage from '@/shared/components/table-view-page.vue';
import SellerFormModal from '@/modules/seller/components/seller-form-modal.vue';
import { SellerPermissions } from '@/modules/seller/seller-permissions';
import i18n from '@/vueI18n';
import Printer from '@/shared/plugins/printer';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore'

const { fields } = SellerModel;

import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { IamService } from '@/modules/iam/iam-service';

export default {
  name: 'app-seller-list-table',
  props: ['sellerType', 'rows'],

  components: {
    [TableViewPage.name]: TableViewPage,
    [SellerFormModal.name]: SellerFormModal
  },

  mounted() {
    this.doMountTable(this.$refs.table);
    // if (this.sellerType == 'distributor') {
    //   const index = 8
    //   this.columns.splice(index, 0, {
    //     name: 'distributorType',
    //     field: 'distributorType',
    //     label: i18n('entities.seller.fields.distributorType'),
    //     align: 'center',
    //     required: true,
    //   });

    //   this.columns.splice(2, 0, {
    //     name: 'distributorName',
    //     field: 'distributorName',
    //     label: i18n('entities.seller.fields.wholesalerName'),
    //     align: 'center',
    //     format: (val) => `${val}`,
    //     required: true,
    //   });
    // }
  },

  data() {
    return {
      filter:'',
      modalVisible:false,
      pdfStyle: `
<style lang="scss">
*#contract-card::-webkit-scrollbar {
  width: 10px !important;
  height: 6px !important;
  border-radius: 50px;
}
 
*#contract-card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
 
*#contract-card::-webkit-scrollbar-thumb {
  border-radius: 50px;
  /* background-color: #003A5D  !important; */
  background-color: rgba(0, 58, 93, 0.82)  !important;
  /* background-color: #00B2E3 !important; */
  /* outline: 5px solid greenyellow !important; */
}
    .head-img{
        width:105px;
        height:21px;
    }
    .underline {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #003A5D
    }
    .b {
        font-weight: bold !important;
    }
    .header-txt {
        font-family: 'Cairo' !important;
        font-weight: bold;
        color: #003A5D;
        font-size: 15px !important;
    }
    .body-txt{
        font-family: 'Cairo' !important;
        color: #003A5D;
        font-size: 12px !important;
    }
    .pdf-container{
        background: #ffffff;
        // max-width: 595px !important;
        max-width: 630px !important;
        width: 100% !important;
        height: fit-content !important;
        margin: 0 auto;
    }

    // .pdf-page-container{
        // background: #808080;
        // height: 100vh !important;
        // width: 75vw !important;
    // }
    .full-width {
        width: 100% !important;
        height: 100% !important;
    }
    .p-20{
        padding: 20px !important;
    }
    .p-10{
        padding: 10px !important;
    }
    .border{
        border: 1.25px solid #00B2E3;
        border-radius: 2px;
    }
</style>

<style lang="scss">
    .underline {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #003A5D
    }
    .b{
        font-weight: bold !important;
    }
    .header-txt{
        font-family: 'Cairo' !important;
        font-weight: bold;
        color: #003A5D;
        font-size: 15px !important;
    }
    .body-txt{
        font-family: 'Cairo' !important;
        color: #003A5D;
        font-size: 11.5px !important;
    }
    .pdf-container{
        background: #ffffff;
        // max-width: 595px !important;
        max-width: 630px !important;
        height: fit-content !important;
        margin: 0 auto;
    }
    // .pdf-page-container{
        // background: #808080;
        // height: 100vh !important;
        // width: 75vw !important;
    // }
    .full-width{
        width: 100% !important;
        height: 100% !important;
    }
    .p-20{
        padding: 20px !important;
    }
    .p-10{
        padding: 10px !important;
    }
    .border{
        border: 1.25px solid #00B2E3;
        border-radius: 2px;
    }
</style>
<style lang="scss">
    .pt-60 {
        padding-top: 60px !important;
    }
    .pr-70{
        padding-left: 70px !important;
        padding-right: 0 !important;
    }
    @media (max-width: 1399px) {
        .pr-70{
        padding-left:  calc(0px + (70 - 0) * ((100vw - 320px) / (1440 - 320))) !important;
    }
    }
    .fit-con {
        width: fit-content;
    }
    .center{
        text-align: center !important;
    }
    .underline {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #003A5D
    }
    .underline2 {
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 4px;
        border-bottom: 1px solid #003A5D
    }
    .b{
        font-weight: bold !important;
    }
    .header-txt{
        font-family: 'Cairo' !important;
        font-weight: bold;
        color: #003A5D;
        font-size: 15px !important;
    }
    .body-txt{
        font-family: 'Cairo' !important;
        color: #003A5D;
        font-size: 12px !important;
    }
    .pdf-container{
        background: #ffffff;
        // max-width: 595px !important;
        max-width: 630px !important;
        height: fit-content !important;
        margin: 0 auto;
    }
    // .pdf-page-container{
        // background: #808080;
        // height: 100vh !important;
        // width: 75vw !important;
    // }
    .full-width{
        width: 100% !important;
        height: 100% !important;
    }
    .p-20{
        padding: 20px !important;
    }
    .p-10{
        padding: 10px !important;
    }
    .border{
        border: 1.25px solid #00B2E3;
        border-radius: 2px;
    }
</style>`,
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'entities.seller.fields.name',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'telephone',
          field: 'telephone',
          label: 'entities.seller.fields.telephone',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'managerName',
          field: 'managerName',
          label: 'entities.seller.fields.managerName',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'managerPhone',
          field: 'managerPhone',
          label: 'entities.seller.fields.managerPhone',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'taxFacilityNumber',
          field: 'taxFacilityNumber',
          label: 'entities.seller.fields.taxFacilityNumber',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'countryId',
          field: 'countryId',
          label: 'entities.seller.fields.countryId',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'cityId',
          field: 'cityId',
          label: 'entities.seller.fields.cityId',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'regionId',
          field: 'regionId',
          label: 'entities.seller.fields.regionId',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'distributorType',
          field: 'distributorType',
          label: 'entities.seller.fields.distributorType',
          align: 'center',
          required: true,
        },
        {
          name: 'distributorName',
          field: 'distributorName',
          label: 'entities.seller.fields.wholesalerName',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center',
        },
      ],
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
    };
  },
  computed: {
    ...mapGetters({
      // rows: 'seller/list/rows',
      count: 'seller/list/count',
      // pagination: 'seller/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'seller/list/loading',
      backgroundLoading: 'seller/list/backgroundLoading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'seller/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language(){
      return i18n.locale
    },

    hasPermissionToEdit() {
      return new SellerPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new SellerPermissions(this.currentUser)
        .destroy;
    },

    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'seller/list/doChangeSort',
      doChangePaginationCurrentPage: 'seller/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'seller/list/doChangePaginationPageSize',
      doMountTable: 'seller/list/doMountTable',
      doDestroy: 'seller/destroy/doDestroy',
      doFetchInBackground: 'seller/list/doFetchInBackground',
      doFetch: 'seller/list/doFetch',
    }),
    distributorName(row){
      if(row.wholesalerId){
        const ws = this.rows.find(I => I.id === row.wholesalerId)
        if(ws) return ws.name
      }
      // return '-'
      return this.language == 'ar' ? 'بيوريكم' : 'Puricom'
    },
     i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return SellerModel.presenter(row, fieldName);
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(`entities.seller.enumerators.distributorType.${val[0].value}`) : 'ـــ';
    },
    // presenterMap(row, fieldName) {
    //   const val = SellerModel.presenter(row, fieldName);
    //   return val[this.language]
    // },
    presenterMap(row, fieldName) {
      const val = row[fieldName]
      const valueEn = val ? val['en'] : null
      const valueAr = val ? val['ar'] : null
      const value = this.language == 'ar'
        ? valueAr ? valueAr : valueEn
        : valueEn ? valueEn : valueAr
      return value ? value : 'ـــ'
    },
    presenterDate(row, fieldName) {
      const val = SellerModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : ''; 
    },
    presenterDay(row, fieldName) {
      return moment(SellerModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = SellerModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogVisible = true
          this.modalVisible = true
          break;
        case 'view':
          this.dialogVisible = true
          this.dialogType = 'view'
          setTimeout(() => {
            console.log(document.querySelector('#contract'));
            document.querySelector('#contract').innerHTML = `${row.contract} ${this.pdfStyle}`
          }, 100);
          // document.querySelector('#contract') = row.contract
          break;
        case 'print':
          document.querySelector('#print').innerHTML = `${row.contract}`
          Printer.doPrint('print')
          // this.$htmlToPaper("print");
          document.querySelector('#print').innerHTML = ``
          setTimeout(() => {
            this.dialogVisible = false
          }, 100);
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },
    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },

    async doConfirm(id) {
      this.selectedId = id
      let batch = await firebase.firestore().batch();
      const docRef = await firebase.firestore().collection('seller').doc(id) 
      batch.update(docRef, {
        status: 'confirmed',
        updatedBy: this.currentUser.id,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await batch.commit()
      await this.doFilterInBackground();
      this.selectedId = undefined
    },
    
    async doRenewalContract(id) {
      this.selectedId = id
      let batch = await firebase.firestore().batch();
      const docRef = await firebase.firestore().collection('seller').doc(id) 
      const renewalDate = moment().locale(this.language).format('DD/MM/YYYY').toString()
      batch.update(docRef, {
        status: 'confirmed',
        contractDate: renewalDate,
        contractEndDate: null,
        updatedBy: this.currentUser.id,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await batch.commit()
      await this.doFilterInBackground();
      this.selectedId = undefined
    },

    async doCancelContract(id) {
      this.selectedId = id
      const seller = this.rows.filter(el => el.id == id)
      let sellerUser = seller && seller.length && seller[0].createdBy 
        ? await FirebaseRepository.findDocument(`user`, seller[0].createdBy) 
        : null

      if (!sellerUser) {
        sellerUser = FirebaseRepository.mapCollection(await firebase.firestore().collection('user').where('phoneNumber', '==', seller[0].telephone).get())
        sellerUser = sellerUser.length ? sellerUser[0] : null
      }


      let batch = await firebase.firestore().batch();
      const docRef = await firebase.firestore().collection('seller').doc(id) 
      const canceledDate = moment().locale(this.language).format('DD/MM/YYYY').toString()
      batch.update(docRef, {
        status: 'canceled',
        canceledDate: canceledDate,
        updatedBy: this.currentUser.id,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      if (sellerUser) {
        IamService.disable([sellerUser.id])
      }
      await batch.commit()
      await this.doFilterInBackground();
      this.selectedId = undefined
    },
    
    async doFilterInBackground() {
      this.$emit('doFilter');
      // const filter = { sellerType: 'distributor' }
      // return this.doFetchInBackground({
      //   filter,
      // });
      // return this.doFetch({
      //   filter,
      // });
    },
  },
};
