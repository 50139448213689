//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ApplicationModel } from '@/modules/seller/application-model';
// import TableViewPage from '@/shared/components/table-view-page.vue';
import { SellerPermissions } from '@/modules/seller/seller-permissions';
import i18n from '@/vueI18n';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore'

const { fields } = ApplicationModel;

export default {
  name: 'app-application-list-grid',
  props: ['rows'],

  components: {
    // [TableViewPage.name]: TableViewPage,
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter: '',
      selected: [],
      modalVisible: false,
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'entities.application.fields.name',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'entities.application.fields.phoneNumber',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'email',
          field: 'email',
          label:'entities.application.fields.email',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'city',
          field: 'city',
          label: 'entities.application.fields.city',
          align: 'center',
          required: true,
          // sortable: true,
        },
        // {
        //   name: 'status',
        //   field: 'status',
        //   label: i18n('entities.application.fields.status'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        {
          name: 'distributorType',
          field: 'distributorType',
          label: 'entities.application.fields.distributorType',
          align: 'center',
          required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'entities.application.fields.createdAt',
          align: 'center',
          required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center',
        },
      ],
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: this.getItemsPerPage(),
        // rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
    };
  },
  computed: {
    ...mapGetters({
      // rows: 'seller/application/rows',
      count: 'seller/application/count',
      // pagination: 'seller/application/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'seller/application/loading',
      backgroundLoading: 'seller/application/backgroundLoading',
      destroyLoading: 'seller/destroyApplication/loading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language(){
      return i18n.locale
    },
    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },

    hasPermissionToEdit() {
      return new SellerPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new SellerPermissions(this.currentUser).destroy;
    },

    cardContainerClass () {
      if (this.$q.screen.gt.xs) {
        return 'grid-masonry grid-masonry--' + (this.$q.screen.gt.sm ? '3' : '2')
      }

      return void 0
    },
    rowsPerPageOptions () {
      if (this.$q.screen.gt.xs) {
        return this.$q.screen.gt.sm ? [ 3, 6, 9 ] : [ 3, 6 ]
      }

      return [ 3 ]
    }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'seller/application/doChangeSort',
      doChangePaginationCurrentPage: 'seller/application/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'seller/application/doChangePaginationPageSize',
      doMountTable: 'seller/application/doMountTable',
      doDestroy: 'seller/destroyApplication/doDestroy',
      doFetchInBackground: 'seller/application/doFetchInBackground',
      doFetch: 'seller/application/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return ApplicationModel.presenter(row, fieldName);
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : '';
    },
    presenterMap(row, fieldName) {
      const val = ApplicationModel.presenter(row, fieldName);
      return val[this.language]
    },
    presenterDateTime(row, fieldName) {
      const val = ApplicationModel.presenter(row, fieldName);
      return val ? moment(val).locale(this.language).format("D MMM, YYYY h:mm A") : 'ـــ'; 
      // return val ? moment(val).locale(this.language).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = ApplicationModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : ''; 
    },
    presenterDay(row, fieldName) {
      return moment(ApplicationModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = ClientModel.presenter(row, fieldName)
      let val = row[fieldName]
      // let date =  `${now} ${time}`;
      return moment(val, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.language).format('hh:mm A')
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogVisible = true
          this.modalVisible = true
          break;
        case 'view':
          this.dialogVisible = true
          this.dialogType = 'view'
          setTimeout(() => {
            console.log(document.querySelector('#contract'));
            document.querySelector('#contract').innerHTML = `${row.contract} ${this.pdfStyle}`
          }, 100);
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },
    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },

    async doConfirm(id) {
      this.selectedId = id
      let batch = await firebase.firestore().batch();
      const docRef = await firebase.firestore().collection('application').doc(id) 
      batch.update(docRef, {
        status: 'contacted',
        updatedBy: this.currentUser.id,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await batch.commit()
      await this.doFilterInBackground();
      this.selectedId = undefined
    },
    async doFilterInBackground() {
      this.$emit('doFilter');
    },
    getItemsPerPage () {
      const { screen } = this.$q
      if (screen.lt.sm) {
        return 3
      }
      if (screen.lt.md) {
        return 6
      }
      if (screen.lt.lg) {
        return 9
      }
      return 12
    },
  },
  watch: {
    '$q.screen.name' () {
      this.pagination.rowsPerPage = this.getItemsPerPage()
    }
  },
};
