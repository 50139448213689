import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
// import DecimalRangeField from '@/shared/fields/decimal-range-field';
// import DecimalField from '@/shared/fields/decimal-field';
// import ImageField from '@/shared/fields/image-field';
// import ImagesField from '@/shared/fields/images-field';
// import IntegerField from '@/shared/fields/integer-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import JsonField from '@/shared/fields/json-field';

function label(name) {
  return i18n(`entities.application.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.application.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {}),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {}),
  email: new StringField('email', label('email'), {}),
  city: new StringField('city', label('cityId'), {}),
  distributorType:  new EnumeratorField('distributorType', label('distributorType'), [
    { value: 'retailer', label: enumeratorLabel('distributorType', 'retailer') },
    { value: 'wholesaler', label: enumeratorLabel('distributorType', 'wholesaler') },
  ],{}),
  status:  new EnumeratorField('status', label('status'), [
    { value: 'pending', label: enumeratorLabel('status', 'pending') },
    { value: 'rejected', label: enumeratorLabel('status', 'rejected') },
    { value: 'contacted', label: enumeratorLabel('status', 'contacted') },
  ],{}),
  
  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy', label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')), 
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class ApplicationModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
