//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { SellerModel } from '@/modules/seller/seller-model';
import  i18n from '@/vueI18n';
import firebase from 'firebase/app'
import 'firebase/firestore'

const { fields } = SellerModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.address,
  fields.telephone,
  fields.taxFacilityNumber,
  fields.manager,
  fields.isOnlineStore,
  fields.websiteUrl,
  fields.countryId,
  fields.cityId,
  fields.regionId,
  fields.sellerType,
  fields.distributorType,
  fields.company,
  fields.status,

// not used in creation but used in update
  fields.retailerId,
  fields.idNumber,
  fields.contract,
  fields.area,
  fields.contractConfirmed,
  fields.contractDate,
  fields.contractEndDate,
  fields.pinCode,
  fields.signature,
]);


export default {
  name: 'app-seller-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal', 'sellerType'],

  data() {
    return {
      step: 1,
      cities: [],
      regions: [],
      area: [''],
      countryOptions: [],
      citiesOptions: [],
      regionsOptions: [],
      active: false,
      model: null,
      rules: formSchema.rules(),
      unsubscribe:undefined
    };
  },
  computed: {
    ...mapGetters({
      countries: 'country/list/rows',
      loadingCountries: 'country/list/loading',

      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      is_screen_xs: 'layout/is_screen_xs',
      rows:'seller/list/rows'
      // sparePartRows: 'sparePart/list/rows',
    }),
    language(){
      return i18n.locale
    },
    fields() {
      return fields;
    },
    wholesalerOptions() {
      return this.rows.filter(I => I.status === 'confirmed').map( I => {
        return {
          value: I.id,
          label: I.name
        }
      })
    },
    countriesList() {
      return this.countries.map(item => {
        return {
          label: item.name[this.language],
          value: item.id, 
        }
      })
    },
    citiesList() {
      return this.cities.filter(city => city.countryId == this.model.countryId).map(item => {
        return {
          label: item.name[this.language],
          value: item.id, 
        }
      })
    },
    regionsInCityList() {
      let regions = []
      let selected_city = this.cities.filter(city => city.id == this.model.cityId)
      
      if (selected_city.length) {
        regions = selected_city[0].regions.map(region => {
          return {
            label: region.name[this.language],
            value: region.id, 
          }
        })
      }
      return regions
    },
    isDistributor() {
      return this.sellerType == 'distributor'
    },
  },
  watch: {
    'model.countryId': function () {
      // if (!newVal) {
        this.model.cityId = undefined
        this.model.regionId = undefined
      // }
    },
    'model.cityId': function () {
      this.model.regionId = undefined
    },
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  async created() {
    this.model = formSchema.initialValues(this.record || {});
    if (!this.isEditing) {
      this.model.isOnlineStore = false
      // this.model.status = 'pending'
      this.model.sellerType = this.sellerType
      // this.model.countryId = this.language == 'ar' ? 'مصر' : 'Egypt'
      this.model.manager = {
        name: undefined,
        phoneNumber: undefined,
      }

      //#region [ test data ]
        // this.model.name = "ناجى محجوب فوزى"
        // this.model.telephone = "201020588829"
        // this.model.taxFacilityNumber = "28002141402615"
        // this.model.manager = {
        //   name: "ناجى محجوب فوزى",
        //   phoneNumber: "201020588829",
        // }
        // this.model.address = "الخصوص"
        // this.model.cityId = "qaliubiya"
        // this.model.regionId = "khanka"
        // this.model.sellerType = "distributor"
        // this.model.distributorType = "retailer"
        // this.model.wholesalerId = "CrPnT9wWyKBCgpJ1uoJ3"
        // this.area = ['الخصوص']
        // // this.model.isOnlineStore = 
        // // this.model.websiteUrl = 
        // // this.model.company = 
        // // this.model.status = 
      //#endregion


    }
    this.doFetchWholesaler()
    this.doFetchCountries()
    this.unsubscribe = await firebase.firestore().collection('city').onSnapshot(async () => {
      this.cities = []
      this.regions = []
      const cities = await firebase.firestore().collection("city").get()
      cities.forEach(async (doc) => {
        let city = doc.data()
        city['id'] = doc.id
        city['regions'] = []
        let regions = await firebase.firestore().collection("city").doc(doc.id).collection("regions").get()
        regions.forEach(async (region) => {
          let regionInCity = region.data()
          regionInCity['id'] = region.id
          city['regions'].push(regionInCity)
          regionInCity['city'] = city.name
          this.regions.push(regionInCity)
        })
        this.cities.push(city)
      })
      // this.loading = false
    })
  },
  methods: {
    ...mapActions({
      authAdd:'auth/authAdd',
      doFetchWholesaler: 'seller/list/doFetchWholesaler',
      doFetchCountries: 'country/list/doFetch',
    }),
    addInput() {
        this.area.push('')
    },
    removeInput(index) {
        this.area.splice(index, 1);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
     i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.$emit('cancel');
    },

    OnClear() {
      this.model.sellerParts = []
    },
    filterCountryFn (val, update) {
      if (val === '') {
        update(() => {
          this.countryOptions = this.countriesList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.countriesOptions = this.countriesList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    filterGovernorateFn (val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.citiesList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.citiesOptions = this.citiesList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    filterCitiesFn (val, update) {
      if (val === '') {
        update(() => {
          this.regionsOptions = this.regionsInCityList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.regionsOptions = this.regionsInCityList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },

    doSelectChangedFields(record, model) {
      let changedFields = []
      const equals = (a, b) =>
        a.length === b.length &&
        a.every((v, i) => v === b[i]);

      Object.keys(model).forEach(key => {
        if (Array.isArray(model[key])) {
          const equ = equals(record[key], model[key])
          if(!equ) changedFields.push(key)
        } else {
          if (record[key] !== model[key]) changedFields.push(key)
        }
      })
      return changedFields
    },
    doUpdateContract(record, model) {
      const changedFields = this.doSelectChangedFields(record, model)
      changedFields.forEach(field => {
        model['contract'] = model['contract'].replace(record[field], model[field]); 
      });
      // console.log(model['contract']);
    },
    async doSubmit() {
      if (this.step < 3) {
        return this.$refs.stepper.next()
      }

      const { id, ...values } = formSchema.cast(this.model);
      values.telephone = `+${values.telephone}`
      values.manager.phoneNumber = `+${values.manager.phoneNumber}`
      values.area = this.area
      // values.status = 'pending'
      this.isEditing
        ? this.doUpdateContract(this.record, values)
        : this.authAdd(values.telephone)
      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
