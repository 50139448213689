//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SellerListTable from '@/modules/seller/components/seller-list-table.vue';
import SellerFormModal from '@/modules/seller/components/seller-form-modal.vue';
import ApplicationListTable from '@/modules/seller/components/application-list-table.vue';
import ApplicationListGrid from '@/modules/seller/components/application-list-grid.vue';
import ApplicationListToolbar from '@/modules/seller/components/application-list-toolbar.vue';

import { mapGetters, mapActions } from 'vuex';
import { SellerPermissions } from '@/modules/seller/seller-permissions';
import firebase from 'firebase/app';
import 'firebase/firestore'
import i18n from '@/vueI18n'

export default {
  name: 'app-seller-distributor-page',

  components: {
    [SellerListTable.name]: SellerListTable,
    [SellerFormModal.name]: SellerFormModal,
    [ApplicationListTable.name]: ApplicationListTable,
    [ApplicationListGrid.name]: ApplicationListGrid,
    [ApplicationListToolbar.name]: ApplicationListToolbar,
  },
   
  async created() {
    // this.doFilterInBackground();
    this.unsubscribe1 = await firebase.firestore().collection('seller').onSnapshot(async querySnapshot => {
      this.doFilterInBackground();
       console.log(`Received query snapshot of size ${querySnapshot.size}`);
    }, err => {
       console.log(`Encountered error: ${err}`);
    });

    this.unsubscribe2 = await firebase.firestore().collection('application').onSnapshot(async querySnapshot => {
      this.doFilterApplication();
       console.log(`Received query snapshot of size ${querySnapshot.size}`);
    }, err => {
       console.log(`Encountered error: ${err}`);
    });
  },
  async mounted() {
    await this.doFilter();
    await this.doFilterApplication();
  },
  beforeDestroy(){
    if(this.unsubscribe1) this.unsubscribe1()
    if(this.unsubscribe2) this.unsubscribe2()
  },
  data() {
    return {
      tab: 'currentDistributors',
      currentDistributors: [],
      newDistributors: [],
      renewalRequests: [],
      endedDistributors:[],

      isListView: false,
      modalVisible: false,
      dialogVisible: false,
      unsubscribe1: undefined,
      unsubscribe2: undefined,
    }
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      rows: 'seller/list/rows',
      count: 'seller/list/count',
      loading: 'seller/list/loading',
      joinRequests: 'seller/application/rows',
    }),
    hasPermissionToCreate() {
      return new SellerPermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },
    watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.distributor') 
    },
  },

  methods: {
    ...mapActions({
      doFetch: 'seller/list/doFetch',
      doFetchInBackground: 'seller/list/doFetchInBackground',
      doFetchApplication: 'seller/application/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },

    // displaySwitch(view) {
    //   switch (view) {
    //     case 'card':
    //       this.isListView = false
    //       break;
    //     case 'list':
    //       this.isListView = true
    //       break;
    //     default:
    //       break;
    //   }
    // },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = { sellerType: 'distributor' }
      await this.doFetch({ filter });

      this.currentDistributors = this.rows.filter(el => el.status == 'confirmed')
      this.newDistributors = this.rows.filter(el => el.status == 'pending')
      this.renewalRequests = this.rows.filter(el => el.status == 'renewal')
      // this.renewalRequests = this.rows.filter(el => el.status == 'renewal')
      this.endedDistributors = this.rows.filter(el => el.status == 'ended')
    },
    async doFilterInBackground() {
      const filter = { sellerType: 'distributor' }
      await this.doFetchInBackground({
        filter,
      });

      this.currentDistributors = this.rows.filter(el => el.status == 'confirmed')
      this.newDistributors = this.rows.filter(el => el.status == 'pending' || el.status == null)
      // this.renewalRequests = this.rows.filter(el => el.status == 'renewal')
      this.renewalRequests = this.rows.filter(el => el.status == 'ended')
      this.endedDistributors = this.rows.filter(el => el.status == 'canceled')
    },
    async doFilterApplication() {
      const filter = { status: 'pending' }
      await this.doFetchApplication({ filter })
    }
  },

};
