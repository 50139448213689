import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class SellerPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.sellerRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.sellerImport,
    );
    this.sellerAutocomplete = permissionChecker.match(
      Permissions.values.sellerAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.sellerCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.sellerEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.sellerDestroy,
    );
  }
}
